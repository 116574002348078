import { useContext } from 'react';
import Link from 'next/link'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import Image from 'next/image'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import style from './Shared.module.css'
import { ThemeContext } from '../../context/theme'
import { FirebaseAuthContext } from '../../context/firebaseAuthContext'
import firebaseSignout from '../../lib/signout'

const IconDarkMode = dynamic(() => import('../icons/iconDarkMode'))
const IconLightMode = dynamic(() => import('../icons/iconLightMode'))
const CustomButton = dynamic(() => import('../buttons/customButton'))


export default function Navigation() {

    const themeApi = useContext(ThemeContext)
    const authApi = useContext(FirebaseAuthContext)
    const { mode, createModeFn } = themeApi
    const { user } = authApi
    const router = useRouter()

    const getMode = (e) => {

        e.preventDefault()

        if (mode === null || mode === 'lightMode') {
            if (window !== 'underfined') {
                window.localStorage.setItem('mode', 'darkMode')
            }
            createModeFn('darkMode')
        } else {
            window.localStorage.setItem('mode', 'lightMode')
            createModeFn('lightMode')
        }
    }

    const pathname = router.asPath

    async function signMeOut(e) {
        e.preventDefault()
        await firebaseSignout()
    }

    return (<>
        <Navbar expand="md" className={style.navBarBg} >
            <Container >
                <Navbar.Brand href="/" className='text-light'>
                    <Image
                    src="/images/logo-final-maroon-line.svg"
                        //src="/images/logo.png"
                        alt="FLGCE Logo image"
                        width="60"
                        height="60"
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Link href="/" passHref legacyBehavior>
                            <Nav.Link className={pathname == '/' ? style.navBarLinksActive : style.navBarLinksColor}>Home</Nav.Link>
                        </Link>

                        <NavDropdown title="Inventory" id="basic-nav-dropdown" className={(pathname.startsWith('/ancientCoins') ||
                            pathname.startsWith('/UScoins') ||
                            pathname.startsWith('/USpaperMoney') ||
                            pathname.startsWith('/worldCoins') ||
                            pathname.startsWith('/paperMoney') ||
                            pathname.startsWith('/medalsTokens') ||
                            pathname.startsWith('/newArrivals') ||
                            pathname.startsWith('/archiveCoins')
                        ) ? 'aaa' : ''}>

                            <Link href="/ancientCoins" passHref legacyBehavior>
                                <NavDropdown.Item className={pathname.startsWith('/ancientCoins') ?
                                    style.dropDownLinksActive :
                                    style.dropDownLinks}>Ancient & Medievals</NavDropdown.Item>
                            </Link>

                            <Link href="/UScoins" passHref legacyBehavior>
                                <NavDropdown.Item className={pathname.startsWith('/UScoins') ?
                                    style.dropDownLinksActive :
                                    style.dropDownLinks}>US Coins</NavDropdown.Item>
                            </Link>

                            <Link href="/USpaperMoney" passHref legacyBehavior>
                                <NavDropdown.Item className={pathname.startsWith('/USpaperMoney') ?
                                    style.dropDownLinksActive :
                                    style.dropDownLinks}>US Paper Money</NavDropdown.Item>
                            </Link>

                            <Link href="/worldCoins" passHref legacyBehavior>
                                <NavDropdown.Item className={pathname.startsWith('/worldCoins') ?
                                    style.dropDownLinksActive :
                                    style.dropDownLinks}> World Coins</NavDropdown.Item>
                            </Link>

                            <Link href="/paperMoney" passHref legacyBehavior>
                                <NavDropdown.Item className={pathname.startsWith('/paperMoney') ?
                                    style.dropDownLinksActive :
                                    style.dropDownLinks}>World Paper Money</NavDropdown.Item>
                            </Link>

                            <Link href="/medalsTokens" passHref legacyBehavior>
                                <NavDropdown.Item className={pathname.startsWith('/medalsTokens') ?
                                    style.dropDownLinksActive :
                                    style.dropDownLinks}>Medals & Tokens</NavDropdown.Item>
                            </Link>

                            <Link href="/newArrivals" passHref legacyBehavior>
                                <NavDropdown.Item className={pathname.startsWith('/newArrivals') ?
                                    style.dropDownLinksActive :
                                    style.dropDownLinks}>New Arrivals</NavDropdown.Item>
                            </Link>

                            <Link href="/archiveCoins" passHref legacyBehavior>
                                <NavDropdown.Item className={pathname.startsWith('/archiveCoins') ?
                                    style.dropDownLinksActive :
                                    style.dropDownLinks}>Archive</NavDropdown.Item>
                            </Link>
                        </NavDropdown>
                        <Link href="/employment" passHref legacyBehavior>
                            <Nav.Link className={pathname == '/employment' ? style.navBarLinksActive : style.navBarLinksColor} >Job Opportunities</Nav.Link>
                        </Link>

                        <Link href="/contact" passHref legacyBehavior>
                            <Nav.Link className={pathname == '/contact' ? style.navBarLinksActive : style.navBarLinksColor} >Contact</Nav.Link>
                        </Link>

                        {(user !== null && user?.uid === process.env.nextauthRoleFbUid && user?.email === process.env.nextauthRoleAdminEmail) ?
                            <><Link href="/adminCoins" passHref legacyBehavior>
                                <Nav.Link className={pathname == '/adminCoins' ? style.navBarLinksActive : style.navBarLinksColor} >Admin Coins</Nav.Link>
                            </Link>                                
                            </> :
                            null}

                    </Nav>
                </Navbar.Collapse>
                <a target='_blank' rel="noreferrer noopener nofollow" href="https://www.ebay.com/str/nnnacoinomania?_trksid=p4429486.m3561.l2563" className={style.ebayBtn} >
                    <span className={style.ebayE}>e</span><span className={style.ebayB}>b</span><span className={style.ebayA}>a</span><span className={style.ebayY}>y</span>
                </a>

                {mode === null || mode === 'darkMode' ?
                    <button onClick={getMode} className={style.btnMode} type="button" >
                        <IconLightMode
                            color='#ffd11a'
                            size='1.3rem'
                            tytle={'Icon light mode'}
                        />
                    </button> :
                    <button onClick={getMode} className={style.btnMode} type="button">
                        <IconDarkMode
                            color='#ffd11a'
                            size='1.3rem'
                            tytle='Icon dark mode'
                        />
                    </button>
                }
                {user === null || user === undefined ?
                    (<CustomButton blueBtn onClick={() => router.push('/login')}>Log in</CustomButton>) :
                    (<CustomButton yellowBtn onClick={signMeOut}>Log out</CustomButton>)
                }

            </Container>
        </Navbar>
        <Container>
            <Row>
                <Col>
                    <h2 className={style.navBarBrandBottom}>FL Gold Coin Exchange, inc</h2>
                </Col>
            </Row>
        </Container>
    </>
    )
}