import firebase_app from "./firebaseConfig";
import { getAuth, signOut } from "firebase/auth";

const auth = getAuth(firebase_app);


export default async function firebaseSignout() {
    await signOut(auth).then(() => {
        // Sign-out successful.
    }).catch((e) => {
        console.log('sign out error: '+e)
        // An error happened.
    });
}
